@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');
@font-face {
  font-family: 'Futura';
  src: url('../src/assets/fonts/unicode.futurab.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Otros estilos globales */
body {
  @apply font-sans;
  height: 100%;
  font-display: swap;
  /* overflow: hidden; */
}

html, body {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}

html::-webkit-scrollbar, body::-webkit-scrollbar {
  display: none;  
}

.container {
  height: 100%;
  overflow-y: scroll;
}

/* ************************************ */
.available-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #111111;
  padding: 10px 20px;
  border-radius: 30px;
  color: #b0b0b0;
  font-size: 16px;
}

.available-indicator .light {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  background-color: #00ff00;
  border-radius: 50%;
  box-shadow: 0 0 10px #00ff00;
  animation: glow 1.5s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px #00ff00;
  }
  100% {
    box-shadow: 0 0 20px #00ff00;
  }
}

/* *************************************** */
.field {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 20px;
  transition: opacity 0.5s ease-out;
}

.field-hidden {
  opacity: 0;
}

@media (max-width: 768px) {
  .field {
    display: none;
  }
}

.mouse {
  width: 50px;
  height: 90px;
  border: 3px solid #adadad;
  border-radius: 60px;
  position: relative;
}

.mouse::before {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #adadad;
  border-radius: 50%;
  opacity: 1;
  animation: wheel 2s infinite;
}

@keyframes wheel {
  to {
    opacity: 0;
    top: 60px;
  }
}

.scroll {
  width: 60px;
  height: 60px;
  border: 2px solid #adadad;
  border-radius: 50%;
  position: relative;
  animation: down 1.5s infinite;
}

.scroll::before {
  content: '';
  position: absolute;
  top: 15px;
  left: 18px;
  width: 18px;
  height: 18px;
  border-left: 2px solid #adadad;
  border-bottom: 2px solid #adadad;
  transform: rotate(-45deg);
}

@keyframes down {
  0% {
    transform: translate(0);
  }
  20% {
    transform: translateY(15px);
  }
  40% {
    transform: translate(0);
  }
}

.arrow {
  width: 0;
  height: 40px;
  border: 1px solid #adadad;
  position: relative;
  animation: scroll 1.5s infinite;
}

.arrow::after {
  content: '';
  display: block;
  position: absolute;
  top: 100%;
  left: -5px;
  width: 1px;
  height: 10px;
  border-top: 10px solid #adadad;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

@keyframes scroll {
  0% {
    height: 40px;
  }
  30% {
    height: 70px;
  }
  60% {
    height: 40px;
  }
}


/* *************************** */

/* *************************** */


/* From Uiverse.io by gharsh11032000 */ 
.buttong {
  cursor: pointer;
  position: relative;
  padding: 10px 24px;
  font-size: 18px;
  color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  border-radius: 34px;
  background-color: transparent;
  transition: all 0.3s cubic-bezier(0.23, 1, 0.320, 1);
  overflow: hidden;
}

.buttong::before {
  content: '';
  position: absolute;
  inset: 0;
  margin: auto;
  width: 90px;
  height: 50px;
  border-radius: inherit;
  scale: 0;
  z-index: -1;
  background-color: #17bd85;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.buttong:hover::before {
  scale: 3;
}

.buttong:hover {
  color: #212121;
  scale: 1.1;
  box-shadow: 0 0px 20px rgba(193, 163, 98,0.4);
}

.buttong:active {
  scale: 1;
}


